
:root {
    --bg: #dcdde1;
    --color-icon: #535c68;
    --social-icon1: #17243d;
    --social-icon2: #e3ae3c;
    --social-icon3: #e4405f;
    --social-icon4: #cd201f;
    --social-icon5: #0077B5;
}

.icon-list {
    padding: 0 1.5rem;
    position: fixed;
    right: 1%;
    bottom: 1%;
    z-index: 99999999999999;
}

.icon-item {
    list-style: none
}

.icon-link {
    display: inline-flex;
    font-size: 3rem;
    text-decoration: none;
    color: var(--color-icon);
    width: 6rem;
    height: 6rem;
    transition: .5s linear;
    position: relative;
    z-index: 1;
    margin: auto;

    a {
        text-decoration: none;
    }
}

.icon-link:hover {
    color: #fff;
    text-decoration: none;
}

.icon-link i {
    margin: auto;
}

.icon-link::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 6rem;
    height: 6rem;
    background: #000;
    border-radius: 50%;
    z-index: -1;
    transform: scale(0);
    transition: 0.3s cubic-bezier(.95, .32, .37, 1.21);
}

.icon-link:hover::before {
    transform: scale(1);
}


.icon-item a:hover:before {
    background: var(--social-icon2);
}



/*# sourceMappingURL=/sm/7c9c120ae2d5b15e3da4e0825a551c32ef16068680518fd0f4ba94cf2c6d7a01.map */
